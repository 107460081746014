import get from 'lodash.get';

export const hcpHomepageTransform = (query) =>  {
  const queryData = query.nodeQuery.entities[0];

  const data = {
    hero: {
      video: get(queryData, 'fieldBannerTop.entity.fieldVideo.entity.url', null),
      header: get(queryData, 'fieldBannerTop.entity.fieldBannerText.value', ''),
    },
    bookmarks: get(queryData, 'fieldContent', []).map((item, index) => ({
      title: get(item, 'entity.fieldNtroText', ''),
      icon: get(item, 'entity.fieldIcon.entity.url', null),
      button_text: get(item, 'entity.fieldLink.title', ''),
      buttonLink: get(item, 'entity.fieldLink.url.path', '/hcp'),
      id: `bookmark-item-${index}`
    })),
    metaData: {
      title: get(queryData, 'fieldMetaTitle', ''),
      description: get(queryData, 'fieldMetatags.entity.fieldDescription', ''),
      keywords: get(queryData, 'fieldMetatags.entity.fieldKeywords', ''),
      hreflang: get(queryData, 'fieldHreflang', []).map(item => ({
        code: get(item, 'entity.fieldLang'),
        url: get(item, 'entity.fieldHref.url.path')
      })),
    },
  }

  return data;
}